import { ethers } from "ethers";
import { createContext } from "react";
import { toast } from "react-toastify";
import abi from "../contracts/claim.json";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const ethProvider = new ethers.providers.JsonRpcProvider(
    "https://eth.llamarpc.com"
  );

  const goerliProvider = new ethers.providers.JsonRpcProvider(
    "https://rpc.ankr.com/eth_goerli"
  );

  const successToast = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const errorToast = (msg) => {
    toast.error(msg, {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const contractAdd = "0xEc5aB53A0f4895E9532dE5ea119dc5Bb277ccd38";
  const viewContract = new ethers.Contract(contractAdd, abi, ethProvider);

  return (
    <AppContext.Provider
      value={{
        ethProvider,
        goerliProvider,
        viewContract,
        successToast,
        errorToast,
        contractAdd,
        abi,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;

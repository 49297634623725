import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/appContext";
import { ethers } from "ethers";

import card from "../assets/bg.png";
import text from "../assets/text.png";
import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";
import abi from "../contracts/claim.json";
import { fromBn } from "../utils";
import Swal from "sweetalert2";

const Main = () => {
  const { viewContract, successToast, errorToast, contractAdd } =
    useContext(AppContext);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();

  const [inputCheckAdd, setInputCheckAdd] = useState();
  // const [inputCheckAdd, setInputCheckAdd] = useState();

  const handleCheckAdd = (e) => {
    setInputCheckAdd(e.target.value);
  };

  const checkClaimAmount = async () => {
    if (!ethers.utils.isAddress(inputCheckAdd)) {
      return errorToast("Not a valid address!");
    }

    try {
      const data = await viewContract.viewClaimableEth(inputCheckAdd);
      console.log(ethers.utils.formatUnits(data, 18));
      Swal.fire({
        text: `Amount of claimable Eth: ${ethers.utils.formatUnits(data, 18)}`,
        icon: "question",
        confirmButtonText: "Cool",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const claim = async () => {
    if (!isConnected) return errorToast("Not Connected");

    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    const writeContract = new ethers.Contract(contractAdd, abi, signer);

    try {
      const data = await viewContract.viewClaimableEth(address);
      const parsedData = ethers.utils.formatUnits(data, 0);

      console.log(parsedData);
      if (parsedData === "0") return errorToast("No Claimable Eth");

      const claim = await writeContract.claimEth();
      await claim.wait();

      successToast("Rewards Claimed!");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="w-full flex justify-center">
      <div className="px-1 h-screen w-full flex-col text-[50px] text-white items-center max-w-screen-xl flex justify-center">
        <div className="relative">
          <img
            src={text}
            alt=""
            className="w-[280px] max-w-[460px] sm:w-full absolute left-1/2 transform -translate-x-1/2 top-6"
          />
          <img
            src={card}
            alt=""
            className="max-w-[560px] w-full"
          />

          {/* inputs */}
          <div className="absolute top-20 left-1/2 transform -translate-x-1/2  flex flex-col gap-1">
            <input
              onChange={handleCheckAdd}
              value={inputCheckAdd}
              type="text"
              min={0}
              className="h-[42px] max-w-[351px] rounded-xl px-3 w-full outline-none flex items-center text-xl justify-center gap-2 bg-[#ffffff] opacity-70 text-black"
            />
            <button
              onClick={checkClaimAmount}
              className="text-[15px] text-black rounded-md bg-white opacity-80"
            >
              Check Claim
            </button>
          </div>

          <div className="flex w-full justify-center gap-3 absolute left-1/2 bottom-[20px] transform -translate-x-1/2">
            <button
              onClick={claim}
              className="w-[280px] h-[42px] text-[15px] text-black rounded-md bg-white opacity-80"
            >
              Claim
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
